export const frUOttawaLabPeerAssessment = {
  title: 'Formulaire d’Évaluation',
  rubric: {
    label: 'Choix:',
    1: '1 = fortement d’accord',
    2: '2 = d’accord',
    3: '3 = pas d’accord',
    4: '4 = fortement pas d’accord',
  },
  header: {
    selfGrade: 'Vous-même',
    criteria: 'Caractéristique',
  },
  partnerName: 'Nom du partenaire',
  partnerCountQuestion: 'Combien de partenaires de laboratoire aviez-vous?',
  noPartner: 'Pas de partenaire',
  onePartner: 'Une partenaire',
  twoPartner: 'Deux partenaires',
  threePartner: 'Trois partenaires',
  fourPartner: 'Quatre partenaires',
  fivePartner: 'Cinq partenaires',
  instruction:
    'Insérez les noms des membres de l’équipe dans les boîtes. Évaluez vous-même pour chacune des caractéristiques avec un des choix. Faites la même chose pour les autres membres de l’équipe. Utilisez quelques mots pour vos choix.',
  attributes: {
    1: 'Était fiable. Assistait à des rencontres. Avait complété leur partie du travail sans plaintes ou excuses.',
    2: 'Avait accepté les tâches confiées de bon cœur',
    3: 'Avait contribué de façon positive à les discussions en groupe.',
    4: 'Avait complété sa partie du travail à temps et de façon complète.',
    5: 'Avait aidé d’autres membres avec leurs tâches au besoin.',
    6: 'Avait soumis du travail précisé et complète.',
    7: 'Avait contribué également à chaque tâche.',
    8: 'Avait bien travaillé avec les autres membres de l’équipe.',
    9: 'En général, était un membre précieux de l’équipe.',
  },
  total: 'Totale des colonnes',
};
